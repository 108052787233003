@value desktop-l,desktop-m,desktop-s,tablet,main-font,secondary-font from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 48px 0;

  @media desktop-s {
    gap: 24px;
  }
}

.categoryHeader {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  font-family: main-font;

  @media desktop-s {
    font-size: 36px;
  }
}

.categoryDescriptionContainer {
  display: flex;
  flex-direction: column;
}

.categoryDescription {
  display: -webkit-box;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: secondary-font;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  transition: all 0.2s ease-in-out;
  height: 100%;
  max-height: 82px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media desktop-s {
    -webkit-line-clamp: 2;
    margin-bottom: 16px;
    max-width: 882px;
  }
}

.categoryDescriptionOpen {
  -webkit-line-clamp: unset;
  max-height: 390px;
}

.subcategoriesContainer {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  gap: 12px;

  @media desktop-s {
    gap: 16px;
  }
}

.subcategory {
  border: 1px solid #131313;
  border-radius: 2px;
  border-radius: 2px;
  padding: 0 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Work Sans';

  @media desktop-s {
    padding: 14px 24px;
  }
}

.chevron {
  transition: transform 0.2s ease-in-out;
}

.chevronButton {
  cursor: pointer;
  margin: auto;
  margin-top: 8px;
  outline: none;
  border: none;
  background-color: transparent;
  width: 16px;

  @media desktop-s {
    margin: 0;
  }
}

@value desktop-l,desktop-m,desktop-s,tablet,main-font,secondary-font from "@/styles/variables.module.css";

.wrapperSmall,
.wrapperMedium,
.wrapperLarge {
  display: flex;
  position: relative;
  flex: 1;
  overflow: hidden;
}

.wrapperSliderSmall {
  min-width: 222px;
  max-width: 222px;
  height: 361px;

  @media desktop-s {
    width: 148px;
    min-width: 148px;
    max-width: 148px;
  }

  @media desktop-m {
    width: 174px;
    min-width: 174px;
    max-width: 174px;
  }

  @media desktop-l {
    width: 201px;
    min-width: 201px;
    max-width: 201px;
  }
}

.wrapperSliderMedium {
  height: 165px;

  @media tablet {
    max-width: 328px;
  }

  @media desktop-s {
    min-width: 315px;
    max-width: 315px;
    height: 607px;
  }

  @media desktop-m {
    min-width: 376px;
    max-width: 376px;
    height: 607px;
  }

  @media desktop-l {
    min-width: 429px;
    max-width: 429px;
    height: 607px;
  }
}

.wrapperSliderLarge {
  width: 222px;
  height: 361px;

  @media tablet {
    width: 328px;
    height: 424px;
  }

  @media desktop-s {
    width: 429px;
    height: 607px;
  }
}

.wrapperSmall a:hover,
.wrapperMedium a:hover,
.wrapperLarge a:hover {
  color: inherit;
}

.containerMedium {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 12px;
  width: 100%;

  @media desktop-l, desktop-m, desktop-s {
    flex-direction: column;
  }
}

.containerLarge,
.containerSmall {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  height: 100%;
}

.containerLarge {
  @media tablet {
    flex: 1;
  }
}

.containerSmall.reverseVerticalArticle,
.containerMedium.reverseVerticalArticle,
.containerLarge.reverseVerticalArticle {
  @media desktop-l, desktop-m, desktop-s {
    flex-direction: column-reverse;
  }
}

.containerSmall.reverseVerticalSupplier,
.containerMedium.reverseVerticalSupplier,
.containerLarge.reverseVerticalSupplier {
  flex-direction: column-reverse;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.headerContainerCardMedium {
  flex: 1;

  @media desktop-s {
    flex: none;
  }
}

.header {
  margin: 0;
  /* space for bookmark icon */
  width: calc(100% - 24px);
  font-weight: 400;
  line-height: 28px;
  font-family: main-font;
  letter-spacing: 0.2px;
}

.headerMedium {
  font-size: 18px;
}

.headerLarge {
  font-size: 20px;

  @media tablet {
    font-size: 24px;
  }
}

.headerSmall {
  font-size: 18px;
}

.headerSmall.supplierCardHeader {
  font-size: 22px;
}

.category {
  margin: 0;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  font-family: secondary-font;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.category span {
  font-weight: 500;
  line-height: 23px;
  font-family: inherit;
  letter-spacing: 0.5px;
}

.bookmark {
  position: absolute;
  top: 8px;
  right: 0;
}

.hoverSVGMedium {
  transform: scale(0.666);
  z-index: 1;

  @media desktop-s {
    transform: scale(1);
  }
}

.hoverSVGLarge {
  transform: scale(0.588);
  z-index: 1;

  @media tablet {
    transform: scale(1);
  }
}

.hoverSVGSmall {
  transform: scale(0.666);
  z-index: 1;

  @media tablet {
    transform: scale(0.666);
  }
}

.imageWrapper {
  position: relative;
  overflow: hidden;

  @media desktop-s {
    flex: 1;
  }
}

.imageWrapperMediumCard {
  flex: 0.75;

  @media desktop-s {
    flex: 1;
  }
}

.imageSliderWrapper {
  flex: 1;
}

.shadowHover {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 1;
  transition: 0.1s ease;
  cursor: pointer;
  background: #f68a5b4d;
}

.containerSmall:hover .shadowHover,
.containerMedium:hover .shadowHover,
.containerLarge:hover .shadowHover {
  @media desktop-s {
    opacity: 1;
  }
}

.linkContainer {
  display: flex;
  flex: 1;
}

.image {
  border-radius: 2px;
  aspect-ratio: 1 / 1.15;
  object-position: top;
}

.supplierCategory {
  display: flex;
  padding-top: 0px;
  width: 100%;
  font-weight: 300;
  font-size: 10px;
  text-transform: uppercase;
}

.locationWrapper {
  width: calc(100% - 24px);
}

.supplierCategory span {
  opacity: 0.5;
}
